import { gql, useLazyQuery } from '@apollo/client'
import { Grid, styled, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChartCard } from '../components'
import { UserInfoContext } from '../context'
import moment from 'moment'

interface IDashboard {
  id: number
  customerId: number
  key: string
  current: number
  limit: number
}

interface IDashboardQuery {
  customerQuery: {
    dashboard: IDashboard[]
  }
}

interface IDashboardReport {
  storageCurrent: number
  storageLimit: number
  translationsCurrent: number
  translationsLimit: number
  usersCurrent: number
  usersLimit: number
}

const DASHBOARD = gql`
  query {
    customerQuery {
      dashboard {
        current
        customerId
        id
        key
        limit
      }
    }
  }
`

const StyledExpiredDate = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: 'bold',
}))

const StyledExpiringDate = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
}))

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState<IDashboard[]>([])
  const [dashboard, setDashboard] = useState<IDashboardReport>({
    storageCurrent: 0,
    storageLimit: 1,
    translationsCurrent: 0,
    translationsLimit: 1,
    usersCurrent: 0,
    usersLimit: 1,
  })

  const { t } = useTranslation()
  const { userInfo } = useContext(UserInfoContext)

  const [
    getDashboard,
    { loading: dashboardLoading },
  ] = useLazyQuery<IDashboardQuery>(DASHBOARD, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const _data = data.customerQuery.dashboard.map(m => m)
      setDashboardData(_data)
    },
  })

  useEffect(() => {
    getDashboard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!dashboardLoading && dashboardData.length > 0) {
      const storage = dashboardData.filter(f => f.key === 'storage')[0]
      const translations = dashboardData.filter(
        f => f.key === 'translations'
      )[0]
      const users = dashboardData.filter(f => f.key === 'users')[0]

      setDashboard({
        storageCurrent: storage?.current,
        storageLimit: storage?.limit > 0 ? storage?.limit : storage?.current,
        translationsCurrent: translations?.current,
        translationsLimit:
          translations?.limit > 0 ? translations?.limit : translations?.current,
        usersCurrent: users?.current,
        usersLimit: users?.limit > 0 ? users?.limit : users?.current,
      })
    }
  }, [dashboardData, dashboardLoading])

  let _expDateText = '-'
  let ComponentExpDate: any = Typography

  if (userInfo.expirationDate) {
    const _expDate = moment(
      userInfo.expirationDate,
      'DD/MM/YYYY HH:mm:ss',
      true
    )
    _expDateText = _expDate.format('L')

    if (_expDate.isBefore(moment())) {
      ComponentExpDate = StyledExpiredDate
    } else if (_expDate.isBefore(moment().add(1, 'month'))) {
      ComponentExpDate = StyledExpiringDate
    }
  }

  return (
    <>
      <Grid item xs={4}>
        <ChartCard
          title={t('storage')}
          max={dashboard.storageLimit}
          current={dashboard.storageCurrent}
          um={'gb'}
          loading={dashboardLoading}
        ></ChartCard>
      </Grid>

      <Grid item xs={4}>
        <ChartCard
          title={t('translations')}
          max={dashboard.translationsLimit}
          current={dashboard.translationsCurrent}
          loading={dashboardLoading}
        ></ChartCard>
      </Grid>
      <Grid item xs={4}>
        <ChartCard
          title={t('users')}
          max={dashboard.usersLimit}
          current={dashboard.usersCurrent}
          loading={dashboardLoading}
        ></ChartCard>
      </Grid>
      <Grid item xs={12}>
        <ComponentExpDate style={{ textAlign: 'center' }}>
          Expiration date: {_expDateText}
        </ComponentExpDate>
      </Grid>
    </>
  )
}

export default Dashboard
