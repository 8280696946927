export default {
  en: {
    translation: {
      customerUser: 'Customer & Users',
      products: 'Products',
      storage: 'Storage',
      translations: 'Translations',
      users: 'Users',
    },
  },
  it: {
    translation: {
      customerUser: 'Clienti e Utenti',
      products: 'Prodotti',
      storage: 'Spazio utilizzato',
      translations: 'Trasformazioni',
      users: 'Utenti',
    },
  },
  es: { translation: {} },
}
