import React from 'react'
import { makeStyles, Paper, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import LiquidFillGauge from 'react-liquid-gauge'
import { color } from 'd3-color'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    height: '100px',
    width: '100%',
    padding: '16px 16px',
    paddingLeft: '90px',
    marginTop: '28px',
  },
  iconContainer: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: '-16px',
    left: '16px',
    height: '100px',
    width: '100px',
    padding: '15px',
    textAlign: 'center',
    zIndex: 1,
    '& *': {
      color: '#ffffff',
      height: '100%',
      width: '100%',
      fontSize: '40px',
    },
  },
  textContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  text: {
    display: 'inline-block',
    textAlign: 'end',
    width: '100%',
  },
  textUm: {
    fontSize: '1rem',
    marginLeft: '3px',
    display: 'inline-block',
    alignSelf: 'flex-end',
  },
  skeleton: {
    marginLeft: 'auto',
  },
}))

const ChartCard = props => {
  const { title, max, current, um, loading } = props
  const classes = useStyles()

  const radius = 35
  const fillColor = '#ffffff'
  const percentage = (current * 100) / max

  return (
    <Paper className={classes.paper}>
      <Paper elevation={3} className={classes.iconContainer}>
        {loading ? (
          <Skeleton animation='wave' variant='rect' />
        ) : (
          <LiquidFillGauge
            style={{ margin: '0 auto' }}
            width={radius * 2}
            height={radius * 2}
            value={percentage}
            percent='%'
            textSize={1.5}
            textOffsetX={0}
            textOffsetY={8}
            riseAnimation
            waveAnimation
            waveFrequency={1}
            waveAmplitude={5}
            gradient
            circleStyle={{
              fill: fillColor,
            }}
            waveStyle={{
              fill: color(fillColor).toString(),
            }}
            textStyle={{
              fill: color(fillColor).toString(),
              fontFamily: 'Roboto',
            }}
            waveTextStyle={{
              fill: color('#114F8E').toString(),
              fontFamily: 'Roboto',
            }}
          />
        )}
      </Paper>

      <div className={classes.textContainer}>
        <Typography className={classes.text} variant='subtitle1'>
          {loading ? (
            <Skeleton
              className={classes.skeleton}
              animation='wave'
              width='60%'
            />
          ) : (
            title
          )}
        </Typography>
        <Typography className={classes.text} variant='h5'>
          {loading ? (
            <Skeleton
              className={classes.skeleton}
              animation='wave'
              width='40%'
            />
          ) : (
            <>
              {current} / {max}
              {um ? <span className={classes.textUm}>{um}</span> : null}
            </>
          )}
        </Typography>
      </div>
    </Paper>
  )
}

export default ChartCard
