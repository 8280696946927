import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Particles from 'react-tsparticles'
import { useLogo } from '../context'
import logoNke from '../images/Logo.png'
import Dashboard from './dashboard'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  cardAction: {
    '&:hover': {
      background: theme.palette.secondary.light,
    },
    padding: theme.spacing(2),
  },
  fixedHeight: {
    height: 240,
  },
  cardContent: {
    height: 100,
  },
  logo: {
    textAlign: 'center',
  },
  skeleton: {
    margin: '0 30rem',
  },
}))

const Home = () => {
  const classes = useStyles()

  const { img, loadingImg } = useLogo()
  const { t } = useTranslation()

  const changeUrl = (url: string) => {
    history.pushState(null, null, url)
  }

  // const openSpp = () => {
  //   window.open(
  //     `${SPP_URL}login?token=${localStorage.getItem('token')}`,
  //     '_blank'
  //   )
  // }

  const _panels = [
    {
      label: t('customerUser'),
      onClick: () => changeUrl('/platform'),
      image: (
        <Particles
          options={{
            background: {
              color: {
                value: '#ebebeb',
              },
            },
            fpsLimit: 60,
            interactivity: {
              detectsOn: 'canvas',
              events: {
                onHover: {
                  enable: true,
                  mode: 'bubble',
                },
                resize: true,
              },
              modes: {
                bubble: {
                  distance: 80,
                  duration: 2,
                  opacity: 0.8,
                  size: 20,
                },
              },
            },
            particles: {
              color: {
                value: '#000000',
              },
              links: {
                color: '#000000',
                distance: 70,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: false,
              },
              move: {
                direction: 'none',
                enable: true,
                outMode: 'bounce',
                random: false,
                speed: 0.8,
                straight: false,
              },
              number: {
                value: 30,
                limit: 0,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: 'circle',
              },
              size: {
                random: true,
                value: 10,
              },
            },
            detectRetina: true,
          }}
        />
      ),
    },
    {
      label: t('products'),
      onClick: () => changeUrl('/models'),
      image: (
        <Particles
          options={{
            background: {
              color: {
                value: '#0d47a1',
              },
            },
            fpsLimit: 60,
            interactivity: {
              detectsOn: 'canvas',
              events: {
                onHover: {
                  enable: true,
                  mode: 'grab',
                },
                resize: true,
              },
              modes: {
                grab: {
                  distance: 110,
                  links: { opacity: 1 },
                },
              },
            },
            particles: {
              color: {
                value: '#ffffff',
              },
              links: {
                color: '#ffffff',
                distance: 70,
                enable: true,
                opacity: 0.4,
                width: 1,
              },
              collisions: {
                enable: false,
              },
              move: {
                direction: 'none',
                enable: true,
                outMode: 'bounce',
                random: false,
                speed: 0.8,
                straight: false,
              },
              number: {
                value: 70,
                limit: 0,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: 'circle',
              },
              size: {
                random: true,
                value: 10,
              },
            },
            detectRetina: true,
          }}
        />
      ),
    },
    {
      label: 'Spare Parts',
      onClick: () => changeUrl('/spp'),
      image: (
        <Particles
          options={{
            background: {
              color: {
                value: '#ebebeb',
              },
            },
            fpsLimit: 60,
            interactivity: {
              detectsOn: 'canvas',
              events: {
                onHover: {
                  enable: true,
                  mode: 'connect',
                },
                resize: true,
              },
              modes: {
                connect: {
                  distance: 50,
                  links: { opacity: 0.5 },
                },
              },
            },
            particles: {
              color: {
                value: ['#114f8e', '#fdb813'],
              },
              links: {
                color: '#114f8e',
                distance: 40,
                enable: true,
                opacity: 1,
                width: 2,
              },
              collisions: {
                enable: false,
              },
              move: {
                direction: 'none',
                enable: true,
                outMode: 'bounce',
                random: false,
                speed: 0.8,
                straight: false,
              },
              number: {
                value: 70,
                limit: 0,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: 'circle',
              },
              size: {
                random: true,
                value: 10,
              },
            },
            detectRetina: true,
          }}
        />
      ),
    },
  ]

  return (
    <Container maxWidth='lg' className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.logo}>
          {loadingImg ? (
            <Skeleton
              className={classes.skeleton}
              animation='wave'
              variant='rect'
              height={60}
            />
          ) : img !== '' ? (
            <img
              src={`data:image/png;base64,${img}`}
              alt='logo'
              style={{ maxHeight: '60px' }}
            />
          ) : (
            <img src={logoNke} alt='logo' style={{ maxHeight: '60px' }} />
          )}
        </Grid>
        {_panels.map((p, i) => (
          <Grid item xs={4} key={i} onClick={p.onClick}>
            <Card className={classes.paper}>
              <CardActionArea className={classes.cardAction}>
                <CardMedia component='div'>{p.image}</CardMedia>
                <CardContent className={classes.cardContent}>
                  <Typography variant='h5'>{p.label}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
        <Dashboard />
      </Grid>
    </Container>
  )
}

export default Home
