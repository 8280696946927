import React from 'react'
import { IUserInfo } from '@NKE/utils'

interface IUserInfoContext {
  userInfo: IUserInfo
}

const UserInfoContext = React.createContext<IUserInfoContext>({
  userInfo: {
    email: '',
    region: '',
    companyName: '',
    type: '',
    loginId: '',
    customerId: '',
    businessPartnerId: '',
    crmCustomerId: '',
    handleRevision: '',
    businessPartner: '',
    businessPartnerCode: '',
    expirationDate: '',
  },
})

export default UserInfoContext
