import React, { useEffect, useState } from "react";
import Home from "./home";
import { getPlatformClient, getUserInfo, IUserInfo, theme } from "@NKE/utils";
import { ApolloProvider } from "@apollo/client";
import { Layout } from "./components";
import { LogoContextProvider, UserInfoContext } from "./context";
import "./i18next";
import moment from "moment";
import i18n from "./i18next";

export default function Root(props) {
  const [UserInfo, setUserInfo] = useState<IUserInfo>({
    email: "",
    region: "",
    companyName: "",
    type: "",
    loginId: "",
    customerId: "",
    businessPartnerId: "",
    crmCustomerId: "",
    handleRevision: "",
    businessPartner: "",
    businessPartnerCode: "",
    expirationDate: "",
  });

  useEffect(() => {
    getUserInfo().then((res) => {
      if (res) {
        if (res.type !== "1" && res.type !== "2")
          history.pushState(null, "", "/spp");

        setUserInfo(res);
      }
    });
  }, []);

  useEffect(() => {
    let _region = localStorage.getItem("i18nextLng");
    if (!_region) _region = UserInfo.region ?? "en";

    i18n.changeLanguage(_region);
    moment.locale(_region);
  }, [UserInfo]);

  const _contextValue = {
    userInfo: UserInfo,
  };

  return (
    <ApolloProvider client={getPlatformClient()}>
      <LogoContextProvider>
        <Layout theme={theme}>
          <UserInfoContext.Provider value={_contextValue}>
            <Home />
          </UserInfoContext.Provider>
        </Layout>
      </LogoContextProvider>
    </ApolloProvider>
  );
}
