import React, { useState, useEffect } from 'react'
import { axiosClient as axios, CUSTOMERS_URL } from '@NKE/utils'

interface ILogoContext {
  img: string
  setImg: (arg: string) => void
  refreshImg: () => void
  loadingImg: boolean
}

const defaultLogo = ''
const LogoContext = React.createContext<ILogoContext>({
  img: defaultLogo,
  setImg: () => {},
  refreshImg: () => {},
  loadingImg: false,
})

type Props = {
  children: React.ReactNode
}

const LogoContextProvider = ({ children }: Props) => {
  const [img, setImg] = useState(defaultLogo)
  const [loadingImg, setLoadingImg] = useState(false)

  useEffect(() => {
    refreshImg()
  }, [])

  const refreshImg = () => {
    setLoadingImg(true)
    const _token = localStorage.getItem('token')
    if (_token) {
      axios
        .get(`${CUSTOMERS_URL}/customer/logo`)
        .then(({ data }) => {
          if (data !== null || data !== undefined) {
            setImg(data)
          }
        })
        .finally(() => setLoadingImg(false))
    }
  }

  return (
    <LogoContext.Provider value={{ img, setImg, refreshImg, loadingImg }}>
      {children}
    </LogoContext.Provider>
  )
}

const useLogo = () => React.useContext(LogoContext)

export { LogoContext, LogoContextProvider, useLogo }
