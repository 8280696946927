import {
  createGenerateClassName,
  CssBaseline,
  makeStyles,
  MuiThemeProvider,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core";
import React from "react";

interface ILayout {
  theme: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    paddingTop: "64px",
  },
}));

const Layout: React.FunctionComponent<ILayout> = ({ children, theme }) => {
  const classes = useStyles();

  const generateClassName = createGenerateClassName({
    disableGlobal: true,
    seed: "themeHomeComponent",
  });

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        {/* <CssBaseline /> */}
        <main className={classes.content}>{children}</main>
      </div>
    </ThemeProvider>
  );
};

export default Layout;
